import SharedObjectApi from "../../../src/api/sharedObjectApi";
import { SharedObjectsSession } from "../../../src/api/types";
import get from 'lodash/get';
import { callGetUserServices } from './userActions';

export type Action = {
  type: 'INIT_SHARED_OBJECTS_SESSION';
  sharedObjectSession: SharedObjectsSession | null;
};

export type ActionLoading = {
  type: 'START_SHARED_OBJECTS_SESSION';
}

export const callSharedObjectSessions = (isInternal: boolean, type: string) => {
  return (dispatch: any) => {
    dispatch({
      type: 'START_SHARED_OBJECTS_SESSION',
    });
    SharedObjectApi.getSharedObjectSession().then((res: any) => {
      if (
        !get(res, 'data.customer', false) || !get(res, 'data.customer.segment', false)
      ) {
        callGetUserServices('', isInternal, dispatch, get(res, 'data', null), type);
      }
      dispatch(initSharedObjectSessions(res));
    }).catch(err => {
      const sharedObjectStatus = get(err, 'response.status', null);
      callGetUserServices(sharedObjectStatus, isInternal, dispatch, null, type);
      dispatch(initSharedObjectSessions(null))
    })
  }
};

export const initSharedObjectSessions = (response: any) => {
  return {
    type: 'INIT_SHARED_OBJECTS_SESSION',
    sharedObjectSession: get(response, 'data', null),
  };
}

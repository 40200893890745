import SessionApi from '../../../src/api/sessionApi';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import { O2Indent, O2Loader, O2Wrap } from 'o2-theme-react';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import useWaitingPageModificator from '../../../src/hooks/useWaitingPageModificator';
import usePersistQueryParams from '../../../src/hooks/usePersistQueryParams';
import getURIParams from '../../utils/getUriParams';
import {
  composeQueryParamUrl,
  composeURIParamsUrl, 
} from '../../../src/api/requestUtils';

const History = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    ttid,
    token,
    serviceId,
    nonO2Service,
  } = getURIParams('/funnel/deeplink/history/');
  const [isNonO2Service, setIsNonO2Service] = useState<boolean>(false);
  const [isNonO2ServiceDisabled, setIsNonO2ServiceDisabled] = useState<boolean>(false);
  const [hasRelevantData, setHasRelevantData] = useState<boolean>(false);
  const user = useSelector<any, any>(state => state.user);
  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const { jwtChecked } = queryString.parse(location.search);
  const transparentWaiting = useWaitingPageModificator();
  const { url } = usePersistQueryParams();

  useEffect(() => {
    if (!user.loading && o2Config !== null) {
      setHasRelevantData(true);
      setIsNonO2ServiceDisabled(o2Config["other.nd5144.disabled"] === "true" ? true : false);
    }
  }, [user, o2Config]);

  useEffect(() => {
    if (nonO2Service && serviceId && !isNonO2ServiceDisabled) {
      setIsNonO2Service(true);
    }
  }, [nonO2Service, serviceId, isNonO2ServiceDisabled]);

  useEffect(() => {
    if (!ttid) {
      history.push('/error');
    }

    if (user.isLoggedIn && hasRelevantData) {
      executeProcess();
    } else {
      if (!user.isLoggedIn && hasRelevantData) {
        const URI_PARAMS = composeURIParamsUrl({
          ttid: ttid ?? undefined,
        });
        const QUERY_PARAMS = composeQueryParamUrl({
          jwtChecked: true,
          url: url && url !== '&' ? url : ''
        });
        const redirectTo = `${window.location.origin}/funnel/deeplink/history/${URI_PARAMS}/${QUERY_PARAMS}`;
        SessionApi.loginAndRedirect(redirectTo, { token });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasRelevantData,
    jwtChecked,
    ttid,
    url,
    user.isLoggedIn,
  ]);

  const executeProcess = () => {
    if (ttid) {
      if (isNonO2Service && !isNonO2ServiceDisabled) {
        dispatch(
          ttsProcess(
            {
              page: 'main',
              params: {
                action: 'troubleList',
                ttId: ttid,
                serviceId,
                nonO2Service,
              },
            },
            props,
            false,
            url ? url : false
          )
        );
      } else {
        dispatch(
          ttsProcess(
            {
              page: 'main',
              params: {
                action: 'troubleList',
                ttId: ttid,
              },
            },
            props,
            false,
            url ? url : false
          )
        );
      }
    } else {
      if (isNonO2Service && !isNonO2ServiceDisabled) {
        dispatch(
          ttsProcess(
            {
              page: 'main',
              params: {
                action: 'troubleList',
                serviceId,
                nonO2Service,
              },
            },
            props,
            false,
            url ? url : false
          )
        );
      } else {
        dispatch(
          ttsProcess(
            {
              page: 'main',
              params: {
                action: 'troubleList',
              },
            },
            props,
            false,
            url ? url : false
          )
        );
      }
      
    }
  };

  return (
    <>
      <O2Indent standalone>
        <O2Wrap center>
          <O2Loader />
        </O2Wrap>
      </O2Indent>
      {transparentWaiting}
    </>
  );
};

export default History;

import { sendGet } from "./requestUtils";
import { GetCustomerListResponse } from "./types";

const API_PATH = '/api/bff-cp-dash/public/v1/customer/';

export default class BffCpDashApi {
  static getCustomerList(type: string) {
    return sendGet<GetCustomerListResponse>(
        API_PATH + 'get-customer-list',
        { type },
        'json',
        true,
        false
    );
  }
}
import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ProcessResponse } from './types';
import { getStore } from '../redux/store/store';
import { addDangerKahlMessage, addSuccessKahlMessage } from '../redux/actions/kahlInfoActions';
import { clearCookies } from '../utils';

let refreshJwtTokenPromise: Promise<any> | null = null;

export function getApiPathPrefix(): any {
  let apfApiLocation = (window as any).g_apfApiLocation;
  return apfApiLocation;
}

export function getApiPath(url: string): any {
  let apfApiLocation = getApiPathPrefix();

  if (url.startsWith('http')) {
    return url;
  } else {
    if (url.startsWith('/')) {
      return apfApiLocation + url;
    }
    return `${apfApiLocation}/api/${url}`;
  }
}

export function composeQueryParamUrl(params: any): string {
  let queryParams = '';
  for (const [key, value] of Object.entries(params)) {
    if (value) queryParams += `${key}=${value}&`;
  }

  return `?${queryParams.slice(0, -1)}`;
}

export function composeURIParamsUrl(params: any): string {
  let uriParams = '';
  for (const [key, value] of Object.entries(params)) {
    if (value) uriParams += `${key}/${value}`;
  }

  return uriParams;
}

export function removeParamFromUrl(url: string, param: string, paramType: 'URI' | 'QUERY' = 'URI'): string {
  const urlObj = new URL(url);

  switch (paramType) {
    case 'QUERY':
      // todo: if we want to delete query parameter from url
      return 'todo';
    case 'URI':
    default:
      let result = '';
      const paramArray = urlObj.pathname.split('/');
      paramArray.shift();
      if (paramArray[paramArray.length - 1] === '') paramArray.pop();
      const indexKey = paramArray.indexOf(param);
      if (indexKey !== -1) paramArray.splice(indexKey, 2);
      paramArray.forEach(element => result += `/${element}`);
      return `${urlObj.origin}${result}`;
  }
}

function callJwtRefresh(
  authApiUrl: string,
  config: AxiosRequestConfig,
  forceLogin: boolean = true
) {
  refreshJwtTokenPromise = new Promise((resolve, reject) => {
    axios
      .request({
        method: 'get',
        url: `${authApiUrl}/refreshJwt`,
        withCredentials: true,
      })
      .then(() => {
        // console.log('Refresh request success');
        // Refresh was successful, resolve the promise
        refreshJwtTokenPromise = null;
        resolve();
      })
      .catch(err => {
        // Refresh failed
        refreshJwtTokenPromise = null;
        if (err.response && err.response.status === 401) {
          // 401 means that our session has expired and can no longer be refreshed,
          // so we'll redirect to login page
          redirectToLoginPage(config, err.response, forceLogin);
          reject('login_redirect');
        } else {
          // If refresh failed for any other reason than 401, we'll simply call original
          // error handler and let it handle it.
          reject('other');
        }
      });
  });
}

async function refreshJwtToken<R>(
  authApiUrl: string,
  config: AxiosRequestConfig,
  forceLogin: boolean = true
): Promise<AxiosResponse<R>> {
  // Start JWT refresh call, if it's not yet active
  if (!refreshJwtTokenPromise) {
    callJwtRefresh(authApiUrl, config, forceLogin);
  }

  try {
    await refreshJwtTokenPromise;
    // console.log('Refresh wait success');
  } catch (reason) {
    // If refresh failed with anything but login redirect, we'll try to send original
    // request again (it will most likely fail again, but let original request handler
    // deal with it).
    // In case of login redirect nothing needs to be done here.
    // console.log('XXXX Failed: ', reason);
    if (reason === 'login_redirect') {
      throw Error('Login redirect');
    }
  }
  // Refresh call was finished, now try to send original request again
  // console.log('Repeated request start');
  return processRequest(config, false);
}

function redirectToLoginPage(
  config: AxiosRequestConfig,
  response: any,
  forceLogin = true
) {
  clearCookies();

  const authApiUrl = response.headers['x-auth-location'];
  let redirectTo;
  if (authApiUrl) {
    if (authApiUrl.endsWith('casLogin')) {
      redirectTo = `${authApiUrl}?redirectTo=${encodeURIComponent(
        window.location.origin
      )}&internalLogin=false&forceLogin=${forceLogin}`;
    } else {
      redirectTo = `${authApiUrl}/casLogin?redirectTo=${encodeURIComponent(
        window.location.origin
      )}&internalLogin=false&forceLogin=${forceLogin}`;
    }
  } else {
    const apfApiLocation = (window as any).g_apfApiLocation;
    redirectTo = `${apfApiLocation}/api/auth/casLogin?redirectTo=${encodeURIComponent(
      window.location.origin
    )}&internalLogin=false&forceLogin=${forceLogin}`;
  }

  window.location.href = redirectTo;
}

export function processRequest<R>(
  config: AxiosRequestConfig,
  allowJwtRefresh: boolean = true,
  forceLogin: boolean = true
): AxiosPromise<R> {
  return axios.request<R>(config).catch(async err => {
    if (err.response && err.response.status === 401) {
      if (!window.location.pathname.includes('token')) {
        if (allowJwtRefresh) {
          let authApiUrl = (window as any).g_apfApiLocation + '/api/auth';

          const res = await refreshJwtToken<R>(authApiUrl, config, forceLogin);
          return res;
        }
        redirectToLoginPage(config, err.response, forceLogin);
        throw Error('login_redirect');
      }
    }
    throw err;
  });
}

function prepareHeaders() {
  return {
    'Content-Type': 'application/json',
  };
}

export function sendGet<R = any>(
  url: string,
  params: object | null,
  responseType:
    | 'json'
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'text'
    | 'stream'
    | undefined = 'json',
  forceLogin: boolean = true,
  allowJwtRefresh = true
): AxiosPromise<R> {
  return processRequest<R>(
    {
      params,
      responseType,
      method: 'get',
      url: getApiPath(url),
      withCredentials: true,
      headers: prepareHeaders(),
    },
    allowJwtRefresh,
    forceLogin
  );
}

export function sendPost<R = any>(
  url: string,
  params: object | null,
  data: any
): AxiosPromise<R> {
  return processRequest<R>({
    params,
    data,
    method: 'post',
    url: getApiPath(url),
    withCredentials: true,
    headers: prepareHeaders(),
  });
}

export function sendPut<R = any>(
  url: string,
  params: object | null,
  data: any
): AxiosPromise<R> {
  return processRequest<R>({
    params,
    data,
    method: 'put',
    url: getApiPath(url),
    withCredentials: true,
    headers: prepareHeaders(),
  });
}

export function sendPatch<R = any>(
  url: string,
  params: object | null,
  data: any
): AxiosPromise<R> {
  return processRequest<R>({
    params,
    data,
    method: 'patch',
    url: getApiPath(url),
    withCredentials: true,
    headers: prepareHeaders(),
  });
}

export function sendDelete<R = any>(
  url: string,
  params: object | null
): AxiosPromise<R> {
  return processRequest<R>({
    params,
    method: 'delete',
    url: getApiPath(url),
    withCredentials: true,
    headers: prepareHeaders(),
  });
}

export function checkSuccessKahlMessages(data: ProcessResponse) {
  if (
    data &&
    data.kahlMessageIds &&
    Array.isArray(data.kahlMessageIds) &&
    data.kahlMessageIds.length !== 0 &&
    data.kahlMessageIds[0] !== null
  ) {
    // eslint-disable-next-line
    data.kahlMessageIds.map(kahlID => {
      getStore().dispatch(addSuccessKahlMessage(kahlID));
    });
    return true;
  }

  return false;
}

export function checkDangerKahlMessages(data: ProcessResponse) {
  if (
    data &&
    data.kahlMessageIds &&
    Array.isArray(data.kahlMessageIds) &&
    data.kahlMessageIds.length !== 0 &&
    data.kahlMessageIds[0] !== null
  ) {
    // eslint-disable-next-line
    data.kahlMessageIds.map(kahlID => {
      getStore().dispatch(addDangerKahlMessage(kahlID));
    });
  }
}

import { setLangtextsDisplayMode } from '../../../src/redux/actions/userActions';
import invert from 'lodash/invert';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { codeListPagesMap } from '../constants/codeListToRouteMap';
import HotKeysModal from './HotKeysModal';

const BasePage = (props: any) => {
  const basepage = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const langtextMode = useSelector<any, any>(
    state => state.user.langtextDisplay
  );
  const user = useSelector<any, any>(state => state.user);
  const [keys, setKeys] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [wireframeNumber, setWireframeNumber] = useState<string>('');
  const [wireframeUrl, setWireframeUrl] = useState<string>('');

  function handleHotKeys(e: any): any {
    if (
      user.userInfo.internal && 
      user.userInfo.roles &&
      user.userInfo.roles.includes('OP_FUNNEL_CC')
    ) {
      if (e.ctrlKey && e.altKey && e.keyCode === 87) {
        setKeys('CTRL+ALT+w');
        setOpen(true);
      } else if (e.ctrlKey && e.altKey && e.keyCode === 72) {
        setKeys('CTRL+ALT+h');
        window.open(wireframeUrl);
        setOpen(false);
      } else if (e.ctrlKey && e.altKey && e.keyCode === 75) {
        if (!langtextMode) {
          setKeys('CTRL+ALT+k');
          dispatch(setLangtextsDisplayMode());
        }
      }
    }
  }

  useEffect(() => {
    if (basepage && basepage.current) {
      basepage.current.focus();
    }
  }, []);

  useEffect(() => {
    let pageName = getCurrPath(window.location.pathname);
    console.log('pagename:' + pageName);
    let wireframes = props.wireframes;
    console.log(wireframes);

    let wireframe = wireframes.filter((item: any) => item.page === pageName)[0];
    console.log('wireframe:' + wireframe);
    if (wireframe && wireframe.wfId && wireframe.htmlLink) {
      setWireframeNumber(wireframe.wfId);
      setWireframeUrl(wireframe.htmlLink);
    }
    // eslint-disable-next-line
  }, [window.location.pathname, wireframeNumber]);

  const getCurrPath = (path: string) => {
    let pageName = path;
    if (pageName.includes('/funnel/trouble-list/')) {
      return 'troubleDetail';
    }
    if (pageName === '/funnel' || pageName === '/funnel/') {
      return 'confirmation';
    }
    if (path.endsWith('/')) {
      pageName = pageName.substring(0, pageName.length - 1);
    }
    return invert(codeListPagesMap)[pageName];
  };

  return (
    <div tabIndex={1} ref={basepage} onKeyDown={(e: any) => handleHotKeys(e)}>
      <HotKeysModal
        open={open}
        keys={keys}
        url={wireframeUrl}
        number={wireframeNumber}
        closeModal={() => setOpen(false)}
      />
      {props.children}
    </div>
  );
};

export default BasePage;

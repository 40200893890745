import { Action, ActionLoading } from '../actions/customerListActions';

const initialState: any = {
  isLoading: false,
  customerListResponse: null
};

export default (state: any = initialState, action: Action | ActionLoading): any => {
  switch (action.type) {
    case 'START_CUSTOMER_LIST':
      return {
        ...state,
        isLoading: true,
      };
    case 'INIT_CUSTOMER_LIST':
      return {
        ...state,
        customerListResponse: action.customerListResponse,
        isLoading: false,
      };
    default:
      return state;
  }
};
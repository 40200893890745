import { getState } from './../store/store';
import { getApiPath } from './../../api/requestUtils';
import { User } from '../reducers/types';
import { UserInfo } from '../../api/types';
import SessionApi from '../../api/sessionApi';
import get from 'lodash/get';
import FunnelServicesApi from '../../api/funnelServicesApi';
import { callGetCustomerList } from './customerListActions';

export type Action =
  | {
    type: 'INIT_USER' | 'FAKE_LOGIN' | 'GET_USER_INFO';
    user: User;
  }
  | {
    type: 'SET_LANGTEXTS_DISPLAY_MODE';
    langtextDisplay: boolean;
  };

export const initUser = (): Action => {
  return {
    type: 'INIT_USER',
    user: {
      isLoggedIn: false,
      hasRole_OP_FUNNEL_CC: false,
      loading: true,
      role: 'external',
      userInfo: {},
      langtextDisplay: false,
    },
  };
};

export const setLangtextsDisplayMode = (): Action => {
  return {
    type: 'SET_LANGTEXTS_DISPLAY_MODE',
    langtextDisplay: true,
  };
};

export const getUserInfo = (userInfo: UserInfo): Action => {
  let isStillLoading = false;

  if (userInfo.userContext === null && userInfo.logged) {
    isStillLoading = true;
  }
  return {
    type: 'GET_USER_INFO',
    user: {
      isLoggedIn: userInfo.logged ? true : false,
      loading: isStillLoading,
      hasRole_OP_FUNNEL_CC: false,
      userInfo,
    },
  };
};

export const logoutAndRedirect = (page: string): any => {
  const store = getState();
  const logoutEndpoint = get(store, 'config.o2Config["link.logout"]', null);
  const apiUrl = getApiPath(logoutEndpoint);
  window.location.href = `${apiUrl}?redirectTo=${page ? window.location.origin + page : window.location.origin + '/funnel'
    }`;
};

export const callGetUserServices = (
  sharedObjectStatus: any | null,
  isInternal: boolean,
  dispatch: any,
  sharedObject: any | null,
  type: string,
) => {
  let serviceResponseStatus: any;

  if (isInternal) {
    if (!window.location.pathname.includes('/nonO2Service')) {
      if (sharedObject !== null) {
        FunnelServicesApi.internalServices(sharedObject?.sharedObjectSession?.externalUser?.cpRefNo)
          .then((res: any) => {
            const response = get(res, 'data', null);
            if (response && response.services[0]) {
              dispatch(getUserServicesSuccess(response.services[0]));
            }
          })
          .catch((err: any) => {
            const serviceStatus = get(err, 'response.status', null);
            serviceResponseStatus = serviceStatus;
            if (
              (sharedObjectStatus === 404 || !sharedObject?.sharedObjectSession?.externalUser?.cpRefNo) &&
              (serviceStatus === 404 || serviceStatus === 401 || serviceStatus === 403 || serviceStatus === 400) &&
              window.location.pathname !== '/funnel/no-context'
            ) {
              window.location.href = '/funnel/no-context';
            }
  
            dispatch(callGetCustomerList(type, isInternal));
        });
      } else {
        if (
          sharedObjectStatus === 404 &&
          window.location.pathname !== '/funnel/no-context'
        ) {
          window.location.href = '/funnel/no-context';
        }
      }

      dispatch(callGetCustomerList(type, isInternal));
    }
  } else {
    SessionApi.getUserServices()
      .then((res: any) => {
        const response = get(res, 'data', null);
        const status = get(res, 'status', null);
        if (response && response.services[0]) {
          dispatch(getUserServicesSuccess(response.services[0]));
        }

        if (status === 204) {
          if (sharedObject?.customer === undefined || sharedObject?.customer?.segment === undefined) {
            dispatch(callGetCustomerList(type, isInternal));
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
        const serviceStatus = get(err, 'response.status', null);
        console.log(serviceStatus);
        serviceResponseStatus = serviceStatus;
        if (
          (serviceStatus === null || serviceStatus === 404 || serviceStatus === 401 || serviceStatus === 403) &&
          window.location.pathname !== '/funnel/no-context'
        ) {
          if (sharedObject?.customer === undefined || sharedObject?.customer?.segment === undefined) {
            dispatch(callGetCustomerList(type, isInternal));
          }
        }
      });
  }


  return serviceResponseStatus;
};

export const getUserContextSuccess = (userContext: any): any => {
  return {
    type: 'GET_USER_CONTEXT_SUCCESS',
    payload: userContext,
    loading: false,
  };
};

export const getUserServicesSuccess = (userServices: any): any => {
  return {
    type: 'GET_USER_SERVICE_SUCCESS',
    payload: userServices,
    loading: false,
  };
};

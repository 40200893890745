import SessionApi from '../api/sessionApi';
import { UserInfo } from '../api/types';
import { getCodeLists } from '../redux/actions/appCacheActions';
import { initApp } from '../redux/actions/configAction';
import {
  getServiceTypeMatrix,
  getTroubleMatrix,
} from '../redux/actions/matrixActions';
import {
  getUserInfo,
  initUser,
} from '../redux/actions/userActions';
import { getWireframes } from '../redux/actions/wireframesAction';
import { O2Layout, O2LayoutColumn, O2Loader } from 'o2-theme-react';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BasePage from './common/BasePage';
import Footer from './layout/Footer';
import Header from './layout/Header';
import MluviiAPIControl from './layout/MluviiAPIControl';
import KahlMessages from './libs/kahlMessages/kahlMessages';
import Routes from './Routes';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import tagTracker from '../utils/tagTracker';
import { callSharedObjectSessions } from '../redux/actions/sharedObjectsActions';
import JournalApi from '../api/journalApi';
import { BrowserRouter } from 'react-router-dom';

//HACK FOR DEEPFORCE UDPDATE IN FUNC COMPONENT
function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

tagTracker();
const App: React.FC = (props: any) => {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const location = window.location;
  const [isLoading, setLoading] = useState(true);
  const { webview, jwtChecked } = queryString.parse(location.search);
  const user = useSelector<any, any>(state => state.user);
  const wireframes = useSelector<any, any>(state => state.appCache.wireframes);
  const destinationToPage = useSelector<any, any>(
    state => state.appCache.codeLists['destinationToPage']
  );
  const kahlInfo = useSelector<any, any>(state => state.kahlInfo);
  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const [isUserIsLogged, setIsUserIsLogged] = useState<null | {
    userInfo: UserInfo;
  }>(null);
  const journalApi = new JournalApi();

  //external user must have at least one role from roleList
  const checkExternalRoles = (userInfo: UserInfo) => {
    if (!userInfo.internal) {
      const roleList: string[] =
        o2Config['other.externalUser.allowed.roleList'];

      if (!userInfo.roles) {
        return false;
      }
      const hasRole = userInfo.roles.some(role => roleList.includes(role));
      return hasRole;
    }
    return true;
  };
  useEffect(() => {
    dispatch(initApp());
    dispatch(initUser());
    dispatch(getWireframes());
    dispatch(getCodeLists());
    dispatch(getTroubleMatrix());
    dispatch(getServiceTypeMatrix());
    SessionApi.getUserInfo()
      .then(res => {
        const userInfo: UserInfo = res.data;
        if (userInfo.logged && userInfo.subscriberType !== 'O2PRP') {
          setIsUserIsLogged({
            userInfo: userInfo,
          });
        } else {
          journalApi.pingJournal({
            event: 'USER_ENTERED_TO_FUNNEL_PORTLET',
          });
        }
        dispatch(getUserInfo(userInfo));
      })
      .catch(err => {
        console.log(err);
      });

    if (webview === 'true') {
      if (!localStorage.getItem('webview')) {
        localStorage.setItem('webview', 'true');
      }
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (
      o2Config &&
      isUserIsLogged !== null &&
      checkExternalRoles(isUserIsLogged.userInfo)
    ) {
      //hotfix to prevent redirect to DP DASHBOARD / WDE Before jwt is checked
      if (
        location.pathname.includes('deeplink') &&
        (
          jwtChecked === undefined ||
          jwtChecked !== 'true'
        )
      ) {
        return;
      }

      dispatch(
        callSharedObjectSessions(
          isUserIsLogged.userInfo.internal
            ? isUserIsLogged.userInfo.internal
            : false,
          isUserIsLogged.userInfo.subscriberType ?? 'O2POP'
        )
      );

      journalApi.pingJournal({
        event: 'USER_ENTERED_TO_FUNNEL_PORTLET',
        username: isUserIsLogged.userInfo.username,
      });
    }
    // eslint-disable-next-line
  }, [o2Config, isUserIsLogged]);

  useEffect(() => {
    if (wireframes.length > 0 && destinationToPage) {
      setLoading(false);
    }
  }, [wireframes, destinationToPage, o2Config]);

  const renderApp = () => {
    return (
      <div className='o2-page'>
        {localStorage.getItem('webview') !== 'true' &&
          <Header />
        }
        <O2Layout>
          <O2LayoutColumn pos='center' func='main'>
            {!user.loading && (
              <>
                <Routes user={user} config={o2Config} />
              </>
            )}
            {user.loading && (
              <O2Loader style={{ textAlign: 'center' }} size='big' />
            )}
          </O2LayoutColumn>
        </O2Layout>
        {localStorage.getItem('webview') !== 'true' &&
          <Footer updateApp={forceUpdate} />
        }
        <MluviiAPIControl />
        <KahlMessages kahlInfo={kahlInfo} />
      </div>
    );
  };

  return (
    <BrowserRouter>
      {(isLoading || o2Config === undefined) && (
        <O2Loader style={{ textAlign: 'center' }} size='big' />
      )}

      {!isLoading && o2Config !== undefined && (
        <GoogleReCaptchaProvider
          reCaptchaKey={o2Config['funnelui.clientapi.google.siteKey']}
        >
          {user.userInfo.logged && user.userInfo.internal && (
            <BasePage path={window.location.pathname} wireframes={wireframes}>
              {renderApp()}
            </BasePage>
          )}
          {!user.userInfo.internal && renderApp()}
        </GoogleReCaptchaProvider>
      )}
    </BrowserRouter>
  );
};

export default App;

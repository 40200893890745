import Langtext from '../../../src/components/common/Langtext';
import LinkMenu from '../../../src/components/common/LinkMenu';
import MapWithAutoComplete from '../../../src/components/common/MapWithAutoComplete';
import PageTitle from '../../../src/components/common/PageTitle';
import Step from '../../../src/components/common/Step';
import { ttsProcess } from '../../../src/redux/actions/ttsActions';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import {
  O2Addon,
  O2AddonContent,
  O2AddonPane,
  O2Button,
  O2ControlWrapper,
  O2Icon,
  O2Indent,
  O2InfoMessage,
  O2Loader,
  O2Section,
  O2TextArea,
  O2TextField,
  O2Wrap,
} from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useAnalytics from '../../../src/hooks/useAnalytics';
import useHandleInappropriateRefresh from '../../../src/hooks/useHandleInappropriateRefresh';
import useWaitingPageModificator from '../../../src/hooks/useWaitingPageModificator';

const SelectTrouble: any = (props: any) => {
  useHandleInappropriateRefresh();
  const history = useHistory();
  const o2Config = useSelector<any, any>(state => state.config.o2Config);
  const coordX = localStorage.getItem('coordX');
  const coordY = localStorage.getItem('coordY');
  const namePage = 'selectTrouble';
  const { setEventName, setTroubleSymptom } = useAnalytics(namePage);
  const dispatch = useDispatch();
  const [isNonO2ServiceDisabled, setIsNonO2ServiceDisabled] = useState<boolean>(false);
  const user = useSelector<any, any>(state => state.user);
  const page = useSelector<any, any>(state => state.pages.pages[namePage]);
  const service = get(page, 'services[0]', {});
  const contactPhone = get(page, 'params.contactPhone', null);
  const contactEmail = get(page, 'params.contactEmail', null);
  const nonO2Service = get(page, 'params.nonO2Service', null);
  const serviceId = get(page, 'params.serviceId', null);
  const transparentWaiting = useWaitingPageModificator();
  const troubleMatrix = useSelector<any, any>(
    state => state.appCache.troubleMatrix
  );
  const serviceTypeMatrix = useSelector<any, any>(
    state => state.appCache.serviceTypeMatrix
  );
  const filteredTroubleMatrix = troubleMatrix.filter(
    (item: any) => item?.serviceGroupId === service?.serviceGroup
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  const [stepsState, setStepsState] = useState<any>({
    troubleSymptomId: 12,
    worklog: '',
    step1: {},
    step2: {},
    step3: {},
    step4: {},
  });
  const [activeStep, setActiveStep] = useState(2);
  const [finishedSteps, setFinishedSteps] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });

  const checkOutage = () => {
    const matrix = serviceTypeMatrix.find(
      (item: any) => item?.serviceGroupId === service?.serviceGroup
    );
    if (matrix && matrix.outage === 1) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (o2Config !== null) {
      setIsNonO2ServiceDisabled(o2Config["other.nd5144.disabled"] === "true" ? true : false);
    }
  }, [o2Config]);

  useEffect(() => {
    if (service.id) {
      setStepsState({
        ...stepsState,
        outage: checkOutage(),
        step1: {
          // TODO: delete after unmock, and setup only from call or default
          serviceName: service.cpName,
          tarif: service.tariffName,
          contactPhone: contactPhone,
          serviceId,
          address: service.address,
        },
        step4: {
          contactPhone: contactPhone ? contactPhone : '',
          contactEmail: (nonO2Service && !isNonO2ServiceDisabled) ? (contactEmail ? contactEmail : '') : undefined,
        },
      });
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [service]);

  const handleInput = (step: any, key: string, value: any) => {
    if (key === 'problem') {
      setTroubleSymptom(value.troubleId);
      setEventName('funnel_problemSelected');
    }

    setStepsState({
      ...stepsState,
      [step]: {
        ...stepsState[step],
        [key]: value,
      },
    });
  };

  const closeOutage = () => {
    setStepsState({
      ...stepsState,
      outage: false,
    });
  };

  const clearStep = (step: number) => {
    setStepsState({
      ...stepsState,
      ['step' + step]: {},
    });
  };

  const finishStep = (step: number, nextStep: number) => {
    setFinishedSteps({
      ...finishedSteps,
      ['step' + step]: true,
    });

    if (nextStep) {
      setActiveStep(nextStep);
    }
  };

  const reeditStep = (step: number) => {
    setFinishedSteps({
      ...finishedSteps,
      ['step' + step]: false,
    });
    setActiveStep(step);
  };

  const handleMap = (data: any) => {
    const { coords } = data;
    setStepsState({
      ...stepsState,
      step3: {
        ...stepsState.step3,
        // TODO: dont return mock when naseptavac will be implemented
        address: data.address,
        coords: coords,
      },
    });
  };

  const proceed = (status?: string) => {
    const { step2, step3, step4 } = stepsState;
    setEventName('funnel_diagnosticsStart');
    dispatch(
      ttsProcess(
        // TODO: unmock to make it working
        {
          page: namePage,
          params: {
            serviceId: service.id,
            troubleSymptomId: step2.problem.troubleId,
            coordinateX: get(step3, 'coords.x', null),
            coordinateY: get(step3, 'coords.y', null),
            localityList: get(step3, 'localityList', null),
            massTtId: step3.massTtId,
            worklog: step4.message,
            nonO2Service: nonO2Service,
            contactEmail: get(step4, 'contactEmail', null),
            contactPhone: stepsState.step4.contactPhone
              ? stepsState.step4.contactPhone.replace('+420', '')
              : contactPhone.replace('+420', ''),
          },
          ttsParams: {
            flowType: 'TT_TROUBLE',
          },
        },
        props
      )
    );

    if (coordX && coordY) {
      localStorage.removeItem('coordX');
      localStorage.removeItem('coordY');
    }
  };

  const mapCallback = (data: any) => {
    const massTtId = data.massTtId ?? 0;
    const localityList = data.localityList ?? 0;

    setStepsState({
      ...stepsState,
      step3: {
        ...stepsState.step3,
        massTtId,
        localityList,
        coords: {
          x: data.coords.x,
          y: data.coords.y,
        },
      },
    });
  };

  const otherServices = () => {
    dispatch(
      ttsProcess(
        {
          page: 'main',
          params: {
            action: 'createTrouble',
          },
        },
        props
      )
    );
  };

  const cancelOutage = () => {
    setEventName('funnel_outageCancel');
    setTimeout(() => {
      history.push('/funnel?jwtChecked=true');
    }, 100);
  };

  const renderOutage = (verification: any) => {
    if (verification === 0 || typeof verification === 'undefined') return null;

    return (
      <O2Indent>
        <O2InfoMessage color='bad'>
          <O2Addon reversed>
            <O2AddonPane indent>
              <O2Icon color='danger' name='exclamation-triangle-fill' />
            </O2AddonPane>
            <O2AddonContent>
              <O2Indent data-test-id='noOutageBar'>
                <h4
                  className='o2web-typo__heading o2-typo--color-danger'
                  color='red'
                >
                  <Langtext text='funnel.selectTrouble.outage.quote1' />
                </h4>
              </O2Indent>
              <O2Indent>
                <Langtext text='funnel.selectTrouble.outage.quote2' />
              </O2Indent>
              <O2Indent>
                <O2Button
                  color='primary'
                  indentRight
                  onClick={() => cancelOutage()}
                >
                  <Langtext text='funnel.selectTrouble.no' />
                </O2Button>
                <O2Button
                  onClick={() => {
                    finishStep(3, 4);
                    setEventName('funnel_outageContinue');
                  }}
                  color='internal'
                >
                  <Langtext text='funnel.selectTrouble.yes' />
                </O2Button>
              </O2Indent>
            </O2AddonContent>
          </O2Addon>
        </O2InfoMessage>
      </O2Indent>
    );
  };

  return (
    <>
      <PageTitle>
        <Langtext text='funnel.selectTrouble.title' />
        <span style={{ display: 'none' }} data-test-id='pageId'>
          {namePage}
        </span>
      </PageTitle>

      {!isLoading && (
        <O2Section>
          {finishedSteps.step1 && (
            <Step
              isActive={false}
              step='1'
              title={stepsState.step1.serviceName}
              subTitle={stepsState.step1.tarif}
              text={stepsState.step1.serviceId}
              linkOnClick={() => otherServices()}
              linkText={
                <Langtext text='funnel.selectTrouble.anotherServiceLink' />
              }
            >
              {user.isLoggedIn && (
                <O2Indent top='join'>{stepsState.step1.address}</O2Indent>
              )}
            </Step>
          )}
          {activeStep === 1 && (
            <Step
              isActive={true}
              step='1'
              title={stepsState.step1.serviceName}
              subTitle={stepsState.step1.tarif}
              text={stepsState.step1.contactPhone}
              link='/'
              linkText={
                <Langtext text='funnel.selectTrouble.anotherServiceLink' />
              }
            >
              <O2Indent top='join'>{stepsState.step1.address}</O2Indent>
            </Step>
          )}
          {stepsState.outage && (
            <O2Indent>
              <O2InfoMessage color='bad'>
                <O2Addon reversed>
                  <O2AddonPane indent>
                    <O2Icon color='danger' name='exclamation-triangle-fill' />
                  </O2AddonPane>
                  <O2AddonContent>
                    <O2Indent>
                      <h4
                        className='o2web-typo__heading o2-typo--color-danger'
                        color='red'
                      >
                        <Langtext text='funnel.selectTrouble.outage.quote1' />
                      </h4>
                    </O2Indent>
                    <O2Indent>
                      <Langtext text='funnel.selectTrouble.outage.quote2' />
                    </O2Indent>
                    <O2Indent>
                      <Link to='/funnel?jwtChecked=true'>
                        <O2Button color='primary' indentRight>
                          <Langtext text='funnel.selectTrouble.no' />
                        </O2Button>
                      </Link>
                      <O2Button onClick={() => closeOutage()} color='internal'>
                        <Langtext text='funnel.selectTrouble.yes' />
                      </O2Button>
                    </O2Indent>
                  </O2AddonContent>
                </O2Addon>
              </O2InfoMessage>
            </O2Indent>
          )}
          {finishedSteps.step2 && (
            <Step
              isActive={false}
              step='2'
              title={<Langtext text='funnel.selectTrouble.selectIssueTitle' />}
              text={
                <Langtext
                  text={`funnel.codelist.troubleNames.${stepsState.step2.problem.troubleId}`}
                />
              }
              linkText={
                <Langtext text='funnel.selectTrouble.anotherIssueLink' />
              }
              linkOnClick={() => reeditStep(2)}
            />
          )}
          {activeStep === 2 && !stepsState.outage && (
            <Step
              isActive={true}
              step='2'
              title={<Langtext text='funnel.selectTrouble.selectIssueTitle' />}
              text={
                <Langtext text='funnel.selectTrouble.selectIssueDescription' />
              }
            >
              <O2Indent>
                <LinkMenu
                  // withTranslations={true}
                  onlyIds={true}
                  columns={2}
                  testId='troubleSymptomLinks'
                  data={filteredTroubleMatrix}
                  click={(item: any) => {
                    handleInput('step2', 'problem', item);
                    console.log(item);
                    if (item.map === 0) {
                      finishStep(2, 4);
                    } else {
                      finishStep(2, 3);
                    }
                  }}
                ></LinkMenu>
              </O2Indent>
            </Step>
          )}
          {finishedSteps.step3 && stepsState.step3.coords && (
            <Step
              isActive={false}
              step='3'
              title={
                <Langtext text='funnel.selectTrouble.issueLocationTitle' />
              }
              text={<Langtext text='funnel.selectTrouble.placeSelectedOnMap' />}
              linkText={<Langtext text='funnel.selectTrouble.change' />}
              linkOnClick={() => reeditStep(3)}
            />
          )}
          {activeStep === 3 && stepsState.step2.problem.map !== 0 && (
            <Step
              isActive={true}
              step='3'
              title={
                <Langtext text='funnel.selectTrouble.issueLocationTitle' />
              }
              text={
                <Langtext text='funnel.selectTrouble.issueLocationDescription' />
              }
            >
              <MapWithAutoComplete
                defaultCoords={
                  get(stepsState, 'step3.coords', false)
                    ? `${stepsState.step3.coords.x};${stepsState.step3.coords.y}`
                    : ''
                }
                address={stepsState.step3.address}
                handleMap={(data: any) => handleMap(data)}
                mapCallback={(data: any) => mapCallback(data)}
                outcomeMessage={stepsState.step3.massTtId}
              />
              <span style={{ display: 'none' }} data-test-id='coordinateXValue'>
                {stepsState.step3.coords ? stepsState.step3.coords.x : ''}
              </span>
              <span style={{ display: 'none' }} data-test-id='coordinateYValue'>
                {stepsState.step3.coords ? stepsState.step3.coords.y : ''}
              </span>
              <span
                style={{ display: 'none' }}
                data-test-id='localityListValue'
              >
                {stepsState.step3.localityList}
              </span>
              <O2Indent>
                <O2Button
                  color='primary'
                  data-test-id='continueMapButton'
                  indentRight
                  onClick={() => {
                    finishStep(3, 4);
                  }}
                >
                  <Langtext text='funnel.selectTrouble.continue' />
                </O2Button>
                <O2Button
                  onClick={() => {
                    clearStep(3);
                    finishStep(3, 4);
                    setEventName('funnel_addressSkipped');
                  }}
                  color='internal'
                  data-test-id='skipMapButton'
                >
                  <Langtext text='funnel.selectTrouble.noLocationContinue' />
                </O2Button>
              </O2Indent>
              {renderOutage(stepsState.step3.massTtId)}
            </Step>
          )}
          {activeStep === 4 && (
            <Step
              isActive={true}
              step={stepsState.step2.problem.map !== 0 ? '4' : '3'}
              title={
                <Langtext text='funnel.selectTrouble.contactNumberTitle' />
              }
              text={
                <Langtext text='funnel.selectTrouble.contactNumberDescription' />
              }
            >
              <O2Indent>
                <O2ControlWrapper>
                  <O2TextField
                    data-test-id='contactPhoneInput'
                    value={stepsState.step4.contactPhone}
                    onChange={e =>
                      handleInput('step4', 'contactPhone', e.target.value)
                    }
                    placeholder={contactPhone}
                  />
                </O2ControlWrapper>
              </O2Indent>
              {(nonO2Service && !isNonO2ServiceDisabled) && (
                <O2Indent>
                  <O2ControlWrapper>
                    <O2TextField
                      data-test-id='contactEmailInput'
                      label={<Langtext text='funnel.selectTrouble.contactEmail' />}
                      value={stepsState.step4.contactEmail}
                      onChange={e =>
                        handleInput('step4', 'contactEmail', e.target.value)
                      }
                    />
                  </O2ControlWrapper>
                </O2Indent>
              )}
              {((user.isLoggedIn && user.userInfo.internal) ||
                stepsState.step2.problem.worklog === 1) && (
                <O2Indent>
                  <O2ControlWrapper>
                    <O2TextArea
                      data-test-id='worklogTextarea'
                      label={<Langtext text='funnel.selectTrouble.issueNote' />}
                      rows={4}
                      controlSize='full-width'
                      onChange={e =>
                        handleInput('step4', 'message', e.target.value)
                      }
                    />
                  </O2ControlWrapper>
                </O2Indent>
              )}
              <O2Indent>
                <O2Button
                  color='important'
                  onClick={() => proceed('pokracovat')}
                  indentRight
                  data-test-id='submitButton'
                >
                  <Langtext text='funnel.selectTrouble.reportIssueBtn' />
                </O2Button>
              </O2Indent>
            </Step>
          )}
        </O2Section>
      )}
      {isLoading && (
        <O2Section>
          <O2Indent standalone>
            <O2Wrap center>
              <O2Loader />
            </O2Wrap>
          </O2Indent>
        </O2Section>
      )}
      {transparentWaiting}
    </>
  );
};

export default SelectTrouble;

import get from 'lodash/get';
import { GetCustomerListResponse } from "../../api/types";
import BffCpDashApi from "../../api/bff-cp-dash";
import { getState } from '../store/store';

export type Action = {
  type: 'INIT_CUSTOMER_LIST';
  customerListResponse: GetCustomerListResponse | null;
};

export type ActionLoading = {
  type: 'START_CUSTOMER_LIST';
}

export const callGetCustomerList = (type: string, isInternal: boolean) => {
  return (dispatch: any) => {
    const store = getState();
    const linkWDEExternal = get(store, 'config.o2Config["link.setCustomerExternal"]', null);
    const linkWDEInternal = get(store, 'config.o2Config["link.setCustomerInternal"]', null);
    const link = isInternal ? linkWDEInternal : linkWDEExternal;

    dispatch({
      type: 'START_CUSTOMER_LIST',
    });

    BffCpDashApi.getCustomerList(type).then((res: any) => {
      if (res.data.customers.length === 1) {
        dispatch(initCustomerList(res));
      } else {
        window.location.href = `${link}${encodeURIComponent(window.location.href)}`;
      }
    }).catch(err => {
      window.location.href = `${link}${encodeURIComponent(window.location.href)}`;
    })
  }
};

export const initCustomerList = (response: any) => {
  return {
    type: 'INIT_CUSTOMER_LIST',
    customerListResponse: get(response, 'data', null),
  };
}
